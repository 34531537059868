// @flow

import React from "react";
import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    AdminReportTitles,
} from "@containers";

/**
 * NetworkInspectionLabels
 */
const NetworkInspectionLabels = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="Verslagtitels">
            <AdminReportTitles id="beheer-verslag-titels" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(NetworkInspectionLabels);
